@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

body {
  margin: 0;
  font-family: 'Electrolize', sans-serif;
  @apply bg-gray-50
}
.ant-btn-primary {
  background-color: #1677ff;
}

.category-item{
  @apply bg-green-700 px-6 py-10 text-white cursor-pointer hover:bg-pink-700 transition-all text-center min-w-[145px] flex items-center justify-center
}


.menu-link .anticon{
  display: flex;
  justify-content: center;
}
.menu-link.active {
  @apply text-[#40a9ff]
}
@media (min-width: 768px) {
  body {
    overflow: hidden;
  }
  .ant-btn-primary {
    background-color: #1677ff;
  }
  
  .categories::-webkit-scrollbar{
    display: none;
  }
}